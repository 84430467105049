<template>
  <div class="content">
    <img class="img" src="https://cdn.qiumo.net.cn/allsmarketmaterial/pageLTSC/pandaAI/top.png" />
    <div class="form">
      <van-field v-model="form.phone" type="tel" maxlength="11" placeholder="请输入您的手机号码" />
      <template>
        <van-field v-model="form.code" type="number" center maxlength="6" autocomplete="one-time-code"
          placeholder="请输入验证码" :class="['input-code', { 'two-cell': num == 2 }]">
          <template slot="right-icon">
            <div>
              <div class="code" v-show="sendAuthCode" @click="getAuthCode">
                获取验证码
              </div>
            </div>
            <div class="code" v-show="!sendAuthCode">
              <span>{{ auth_time }}秒</span>重新获取
            </div>
          </template>
        </van-field>
      </template>
      <img src="https://cdn.qiumo.net.cn/allsmarketmaterial/pageLTSC/pandaAI/submit.png" class="submit"
        @click="onSubmit" />
      <div class="checked">
        <van-checkbox checked-color="#ffffff" v-model="checked" icon-size="16px" shape="square">
        </van-checkbox>
        <div style="margin-left: 4px;margin-top: -2px;">
          <div class="checked-text">
            <span>我已阅读并同意</span>
            <span class="book" @click.stop="onScroll">订购须知</span>
            <span>、</span>
            <span class="book" @click.stop="showTermsDialog = true">隐私条款</span>
            <span>等协议</span>
          </div>
          <div class="checked-text">熊猫AI数字人权益19元包 资费：19元/月</div>
        </div>
      </div>
    </div>
    <img class="img" style="width: 94vw;margin-left: 3vw;"
      src="https://cdn.qiumo.net.cn/allsmarketmaterial/pageLTSC/pandaAI/equity.png" />
    <div class="rule">
      <div class="rule-title">☛ 订购须知 ☚</div>
      <div class="rule-contant">{{ ruleText }}</div>
    </div>
    <img class="img" src="https://cdn.qiumo.net.cn/allsmarketmaterial/pageLTSC/pandaAI/bottom1.png" />
    <van-popup v-model="showTermsDialog" style="background: none">
      <div class="policy-wrap">
        <div class="title">
          <h3>个人信息授权与保护声明</h3>
          <img class="close" src="https://cdn.qiumo.net.cn/allsmarketmaterial/pageLTSC/pandaAI/close.png"
            @click="showTermsDialog = false" />
        </div>
        <div class="content_wrap">{{ termText }}</div>
      </div>
    </van-popup>
    <van-popup v-model="showSubmitDialog" :close-on-click-overlay="false" style="background: none">
      <div class="dialog_wrap">
        <img class="dialog-img" src="https://cdn.qiumo.net.cn/allsmarketmaterial/pageLTSC/pandaAI/dialog_bg.png" />
        <img class="cancel" src="https://cdn.qiumo.net.cn/allsmarketmaterial/pageLTSC/pandaAI/cancel.png"
          @click="showSubmitDialog = false" />
        <img class="confirm" src="https://cdn.qiumo.net.cn/allsmarketmaterial/pageLTSC/pandaAI/confirm.png"
          @click="handleSubmit" />
      </div>
    </van-popup>
  </div>
</template>

<script>
import { tradeOrder, getVerificationCode } from "@/api/qiuMoFusion/index.js";
import { ruleText, termText } from "./data/pandaAI";
import tailJumpMixin from "@/utils/tailJump.js";
export default {
  mixins: [tailJumpMixin],
  components: {
  },
  data() {
    return {
      yzm_loading: false,
      sendAuthCode: true /*布尔值，通过v-show控制显示‘获取按钮'还是‘倒计时' */,
      auth_time: 0 /*倒计时 计数器*/,
      form: {
        productNo: "814209295603",
        phone: "",
        characteristic: "",
        marketUrl: "",
        code: "", //绑定输入验证码框框
        sid: "",
        data: "",
        orderId: "",
      },
      num: 1,
      checked: false,
      ruleText,
      termText,
      showTermsDialog: false,
      showSubmitDialog: false,
    };
  },
  computed: {
    link() {
      return `${location.origin}/pages/qiuMoFusion/LT/sichuan/hotVip`;
    },
  },
  created() {
    const { from = "", phone = "", reasonCode = "" } = this.$route.query;
    const { href = "", origin = "" } = window.location;
    this.form = {
      ...this.form,
      characteristic: from + reasonCode,
      marketUrl: href,
      phone: phone,
    };
    let state = {
      url: document.URL,
    };
    history.pushState(state, "title", document.URL);
  },
  mounted() {
    const that = this;
    window.onpopstate = function (event) {
      // 返回拦截
      window.location.replace(
        that.tailJumpUrl(that.link, `QIUMORONGHE`, that.form.phone, "退出")
      );
    };
  },
  watch: {
    form: {
      handler(value) {
        const { phone = "", code } = value;
        if (phone.length < 11) {
          this.num = 1;
        } else if (this.sendAuthCode && code.length == 0) {
          this.num = 2;
        } else if (code.length < 6) {
          this.num = 3;
        } else if (code.length == 6 && phone.length == 11) {
          this.num = 4;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    onScroll() {
      const ruleEl = document.querySelector(".rule");
      ruleEl.scrollIntoView({ behavior: "smooth" });
    },
    getAuthCode() {
      if (this.yzm_loading) return;
      if (!this.form.phone)
        return this.$toast.fail({
          message: "请输入手机号码",
        });
      var filterPhone = /^1[3456789]\d{9}$/;
      if (!filterPhone.test(this.form.phone)) {
        this.$toast.fail({
          message: "手机号码输入错误",
        });
      } else {
        this.yzm_loading = true;
        try {
          getVerificationCode(
            JSON.stringify({ ...this.form, appPackage: navigator.userAgent })
          )
            .then((res) => {
              this.yzm_loading = false;
              if (res.code != 0) {
                this.$dialog.alert({ message: res.message || '验证码获取失败' });
                setTimeout(() => {
                  window.location.replace(
                    this.tailJumpUrl(
                      this.link,
                      `QIUMORONGHE`,
                      this.form.phone,
                      "验证码下发失败"
                    )
                  );
                }, 3000);
              } else {
                this.form.data = res.data.data.data;
                this.form.orderId = res.data.data.orderId;
                // 设置倒计时秒
                this.sendAuthCode = false;
                this.auth_time = 120;
                let auth_timetimer = setInterval(() => {
                  this.auth_time--;
                  if (this.auth_time <= 0) {
                    this.sendAuthCode = true;
                    clearInterval(auth_timetimer);
                  }
                }, 1000);
              }
            })
            .catch((error) => {
              this.yzm_loading = false;
            });
        } finally {
          this.yzm_loading = false;
        }
      }
    },
    handleSubmit() {
      this.showSubmitDialog = false;
      this.$toast.loading({
        duration: 0,
        forbidClick: true,
        mask: true,
        message: "加载中...",
      });
      tradeOrder(JSON.stringify(this.form)).then((res) => {
        this.$toast.clear();
        if (res.code != 0) {
          this.$toast({
            duration: 8000,
            message: res.message || '提交失败',
          });
          setTimeout(() => {
            window.location.replace(
              this.tailJumpUrl(
                this.link,
                `QIUMORONGHE`,
                this.form.phone,
                "失败"
              )
            );
          }, 3000);
        } else {
          this.$dialog.alert({
            title: "提交成功",
            message: "具体结果，请以短信提示为准",
            messageAlign: "center",
          });
          setTimeout(() => {
            window.location.replace(
              this.tailJumpUrl(
                this.link,
                `QIUMORONGHE`,
                this.form.phone,
                "成功"
              )
            );
          }, 3000);
        }
      });
    },

    onSubmit() {
      var filtercode = /^\d{6}$/;
      var filterPhone = /^1[3456789]\d{9}$/;
      if (!this.form.phone) {
        this.$toast.fail({
          message: "请输入手机号码",
        });
      } else if (!filterPhone.test(this.form.phone)) {
        this.$toast.fail({
          message: "手机号码输入错误",
        });
      } else if (!this.form.code) {
        this.$toast.fail({
          message: "请输入验证码",
        });
      } else if (!filtercode.test(this.form.code)) {
        this.$toast.fail({
          message: "请输入6位验证码",
        });
      } else if (!this.checked) {
        this.$toast.fail({
          message: "请勾选下方隐私协议！",
        });
      } else {
        // this.handleSubmit();
        this.showSubmitDialog = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  background-color: #eb3242;

  .img {
    width: 100%;
  }

  .form {
    position: relative;
    padding: 0 30px;

    .checked {
      margin-top: 32px;
      margin-bottom: 64px;
      display: flex;
      font-size: 24px;
      align-items: flex-start;
      justify-content: center;
      flex-wrap: wrap;
      line-height: 36px;

      .checked-text {
        color: #ffffff;
        font-weight: 400 !important;
      }

      .text2 {
        padding-left: 20px;
      }

      .book {
        text-decoration: underline;
        cursor: pointer;
      }

      ::v-deep .van-checkbox {
        .van-checkbox__icon {
          width: 30px;
          height: 30px;
        }

        .van-icon {
          width: 30px;
          height: 30px;
          border: 2px solid #fff;
        }

        &__icon--checked .van-icon {
          color: red;
        }
      }
    }

    ::v-deep .van-cell {
      width: 660px;
      height: 100px;
      background-color: #ffffff;
      border-radius: 50px;
      margin: 0px auto 0;
      overflow: hidden;
      box-sizing: border-box;
      padding: 0 0 0 46px;
      font-size: 36px;

      .van-field__control {
        font-weight: 400 !important;
      }

      &.input-code {
        margin-top: 34px;

        .code {
          width: 229px;
          height: 100px;
          text-align: center;
          line-height: 100px;
          font-size: 34px;
          font-weight: normal;
          color: #eb6930;
        }
      }

      .van-field__body {
        height: 100%;
      }

      &::after {
        border: none;
      }

      .van-field__control {
        font-size: 36px;

        &::-webkit-input-placeholder {
          font-size: 36px;
          font-weight: 400;
          color: #969696;
        }
      }
    }

    .submit {
      width: 660px;
      display: block;
      margin: 50px auto 0;
    }
  }

  .rule {
    color: #fff;
    padding: 32px 60px;
    font-weight: 400 !important;

    .rule-title {
      text-align: center;
      text-decoration: underline;
      font-size: 40px;
      font-weight: 400 !important;
    }

    .rule-contant {
      white-space: pre-wrap;
      font-weight: 400 !important;
      font-size: 26px;
      line-height: 2;
    }
  }

  .policy-wrap {
    width: 90vw;
    height: 85vh;
    background: #fff;
    border-radius: 18px;
    text-align: justify;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    .title {
      height: 100px;
      text-align: center;
      line-height: 100px;
      background: #ffa100;
      position: relative;
      flex: none;

      .close {
        position: absolute;
        width: 50px;
        height: 50px;
        top: 25px;
        right: 30px;
      }
    }

    .content_wrap {
      flex: 1;
      overflow: auto;
      white-space: pre-wrap;
      font-size: 24px;
      color: #000;
      font-weight: 400 !important;
      padding: 20px;
    }
  }

  .dialog_wrap {
    width: 720px;
    height: 707px;
    position: relative;

    .dialog-img {
      width: 100%;
      height: 100%;
    }

    .cancel {
      position: absolute;
      left: 140px;
      bottom: 70px;
      width: 140px;
    }

    .confirm {
      position: absolute;
      right: 120px;
      bottom: 40px;
      width: 228px;
      height: 84px;
    }
  }
}
</style>
